import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import tabletFullScreen from '../lib/tablet-full-screen';

import RegisterModalComponent from '../components/register-modal/register-modal.jsx';
import BrowserModalComponent from '../components/browser-modal/browser-modal.jsx';
import supportedBrowser from '../lib/supported-browser';

import {notify} from 'react-notify-toast';
import UserApi from '../api/user';
import sessionstorage from '../utils/sessionstorage'

import {
    closeRegister,
    openLogin
} from '../reducers/modals';

import {
    userLogin
} from '../reducers/user';


class RegisterModal extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleCancel',
            'handleRegister',
            'handleInputChange',
            'handleGetCode',
            'getImageCode'
        ]);

        this.state = {
            username: '',
            password: '',
            mobile: '',
            code: '',
            img_code: '',
            error: '',
            imageUrl: '',
            key: ''
        };
    }
    componentDidMount() {
      this.getImageCode();
    }
    handleInputChange(event) {
      this.setState({error: ''});

      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;

      this.setState({
        [name]: value
      });
    }

    handleCancel () {
        this.props.onCancel();
    }
    handleGetCode() {
      if(this.state.mobile === ""||this.state.mobile === null){
        this.setState({error: '手机号码不能为空'});
      }else if(this.state.img_code === ""||this.state.img_code === null){
        this.setState({error: '图形验证码不能为空'});
      }else{
        const {mobile,key,img_code} = this.state;
        UserApi.getVerificationCode({mobile,key,img_code}).then(res => {
          notify.show('已经发送验证码，请查看手机', 'success');
        }).catch( err => {
          this.setState({error: err.message});
        });
      }

    }
    getImageCode() {
      UserApi.getImageCode().then(res => {
        console.log(res);
        this.setState({imageUrl: res.img, key: res.key});
      }).catch( err => {
        this.setState({error: err.message});
      });

    }
    handleRegister () {
      if(this.state.username === ''||this.state.username === null){
        this.setState({error: '用户名不能为空'});
      }else if(this.state.username.length<3){
        this.setState({error: '用户名不能少于3个字符'});
      }else if(this.state.password === ""||this.state.password === null){
        this.setState({error: '密码不能为空'});
      }else if(this.state.password !== this.state.passwordConfirm){
        this.setState({error: '两次输入的密码不一致'});
      }else if(this.state.mobile === ""||this.state.mobile === null){
        this.setState({error: '手机号码不能为空'});
      }else if(this.state.code === ""||this.state.code === null){
        this.setState({error: '手机验证码不能为空'});
      }else if(this.state.img_code === ""||this.state.img_code === null){
        this.setState({error: '图形验证码不能为空'});
      }else{
        const {username, password,mobile,code,img_code,key} = this.state;
        UserApi.create({username,password,mobile,code,img_code,key}).then(res => {
          notify.show('注册成功，请登录', 'success');
          this.props.onRegister();
        }).catch( err => {
          this.setState({error: err.message});
        });
      }
    }
    render () {
        return (supportedBrowser() ?
            <RegisterModalComponent
              error={this.state.error}
              imageUrl={this.state.imageUrl}
              onCancel={this.handleCancel}
              onRegister={this.handleRegister}
              onInputChange={this.handleInputChange}
              onGetCode={this.handleGetCode}
              onImageCode={this.getImageCode}
            /> :
            <BrowserModalComponent
                onBack={this.handleCancel}
            />
        );
    }
}

RegisterModal.propTypes = {
    onCancel: PropTypes.func,
    onRegister: PropTypes.func,
    onGetCode: PropTypes.func
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
    onCancel: () => {
        dispatch(closeRegister());
    },
    onRegister: () => {
      dispatch(closeRegister());
      dispatch(openLogin());
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegisterModal);
