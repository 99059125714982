import VM from 'scratch-vm';
import storage from '../lib/storage';

const SET_VM = 'scratch-gui/community/SET_VM';
const LOAD_PROJECT = 'scratch-gui/community/LOAD_PROJECT';
const SET_FULL_SCREEN = 'scratch-gui/community/SET_FULL_SCREEN';

const defaultVM = new VM();
defaultVM.attachStorage(storage);
const initialState = {
  projectId: 0,
  projectName: '',
  projectData: {},
  vm: defaultVM,
  isFullScreen: false
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case SET_FULL_SCREEN:
        return Object.assign({}, state, {
            isFullScreen: action.isFullScreen
        });
    case SET_VM:
        return Object.assign({}, state, {
            vm: action.vm
        });
    case LOAD_PROJECT:
        return Object.assign({}, state, {
            projectId: action.data.id,
            projectName: action.data.name,
            projectData: action.data.data
        });
    default:
        return state;
    }
};
const setVM = function (vm) {
    return {
        type: SET_VM,
        vm: vm
    };
};
const loadProject = function (data) {
  return {
      type: LOAD_PROJECT,
      data: data
  };
};
const setFullScreen = function (isFullScreen) {
    console.log('eeeee')
    return {
        type: SET_FULL_SCREEN,
        isFullScreen: isFullScreen
    };
};
export {
    reducer as default,
    initialState as communityInitialState,
    setVM,
    loadProject,
    setFullScreen
};
