import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import tabletFullScreen from '../lib/tablet-full-screen';

import ResetPasswordComponent from '../components/reset-password-modal/reset-password-modal.jsx';
import BrowserModalComponent from '../components/browser-modal/browser-modal.jsx';
import supportedBrowser from '../lib/supported-browser';

import {notify} from 'react-notify-toast';
import UserApi from '../api/user';
import sessionstorage from '../utils/sessionstorage'

import {
    closeResetPassword,
    openLogin
} from '../reducers/modals';

import {
    userLogin
} from '../reducers/user';


class ResetPassword extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleCancel',
            'handleResetPassword',
            'handleInputChange',
            'handleGetCode',
            'getImageCode'
        ]);

        this.state = {
            password: '',
            mobile: '',
            code: '',
            img_code: '',
            error: '',
            imageUrl: '',
            key: ''
        };
    }
    componentDidMount() {
      this.getImageCode();
    }
    handleInputChange(event) {
      this.setState({error: ''});

      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;

      this.setState({
        [name]: value
      });
    }

    handleCancel () {
        this.props.onCancel();
    }
    handleGetCode() {
      if(this.state.mobile === ""||this.state.mobile === null){
        this.setState({error: '手机号码不能为空'});
      }else if(this.state.img_code === ""||this.state.img_code === null){
        this.setState({error: '图形验证码不能为空'});
      }else{
        const {mobile,key,img_code} = this.state;
        UserApi.getVerificationCode({mobile,key,img_code}).then(res => {
          notify.show('已经发送验证码，请查看手机', 'success');
        }).catch( err => {
          this.setState({error: err.message});
        });
      }

    }
    getImageCode() {
      UserApi.getImageCode().then(res => {
        console.log(res);
        this.setState({imageUrl: res.img, key: res.key});
      }).catch( err => {
        this.setState({error: err.message});
      });

    }
    handleResetPassword () {
      if(this.state.password === ""||this.state.password === null){
        this.setState({error: '密码不能为空'});
      }else if(this.state.password !== this.state.passwordConfirm){
        this.setState({error: '两次输入的密码不一致'});
      }else if(this.state.mobile === ""||this.state.mobile === null){
        this.setState({error: '手机号码不能为空'});
      }else if(this.state.code === ""||this.state.code === null){
        this.setState({error: '手机验证码不能为空'});
      }else if(this.state.img_code === ""||this.state.img_code === null){
        this.setState({error: '图形验证码不能为空'});
      }else{
        const {password,mobile,code,img_code,key} = this.state;
        UserApi.resetPassword({password,mobile,code,img_code,key}).then(res => {
          notify.show('重置密码成功，请登录', 'success');
          this.props.onResetPassword();
        }).catch( err => {
          this.setState({error: err.message});
        });
      }
    }
    render () {
        return (supportedBrowser() ?
            <ResetPasswordComponent
              error={this.state.error}
              imageUrl={this.state.imageUrl}
              onCancel={this.handleCancel}
              onResetPassword={this.handleResetPassword}
              onInputChange={this.handleInputChange}
              onGetCode={this.handleGetCode}
              onImageCode={this.getImageCode}
            /> :
            <BrowserModalComponent
                onBack={this.handleCancel}
            />
        );
    }
}

ResetPassword.propTypes = {
    onCancel: PropTypes.func,
    onResetPassword: PropTypes.func,
    onGetCode: PropTypes.func
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
    onCancel: () => {
        dispatch(closeResetPassword());
    },
    onResetPassword: () => {
      dispatch(closeResetPassword());
      dispatch(openLogin());
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPassword);
