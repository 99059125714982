import request from '../utils/request';

export default {
  query() {
    return request('/api/users');
  },
  login(params) {
    return request('/api/user/login', {
      method: 'POST',
      body: params,
    });
  },
  queryCurrent() {
    return request('/api/user/getCurUserInfo');
  },
  getVerificationCode(params) {
    return request('/api/user/getVerificationCode', {
      method: 'POST',
      body: params,
    });
  },
  create(params) {
    return request('/api/user/create', {
      method: 'POST',
      body: params,
    });
  },
  getImageCode(params) {
    return request('/api/imageCode/get');
  },
  resetPassword(params) {
    return request('/api/user/resetPassword', {
      method: 'POST',
      body: params,
    });
  },
  findSearchHistory(params) {
    return request('/api/user/findSearchHistory', {
      method: 'POST',
      body: params,
    });
  },
};
