const SET_WORKSPACE = 'scratch-gui/workspace/SET_WORKSPACE';

const initialState = {};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case SET_WORKSPACE:
        return action.ws
    default:
        return state;
    }
};

const setWorkspace = function (workspace) {
    return {
        type: SET_WORKSPACE,
        ws: workspace
    };
};


export {
    reducer as default,
    initialState as workspaceInitialState,
    setWorkspace
};
