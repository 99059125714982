import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from 'react-modal';
import Box from '../box/box.jsx';
import {defineMessages, injectIntl, intlShape, FormattedMessage} from 'react-intl';

import Label from '../forms/label.jsx';
import Input from '../forms/input.jsx';

import BufferedInputHOC from '../forms/buffered-input-hoc.jsx';

import styles from './share-modal.css';
import bstyle from '../../css/bootstrap.min.css';

const BufferedInput = BufferedInputHOC(Input);

const messages = defineMessages({
    label: {
        id: 'gui.previewInfo.label',
        defaultMessage: 'Try Scratch 3.0',
        description: 'Scratch 3.0 modal label - for accessibility'
    }
});
const ShareModal = ({intl, ...props}) => (
    <ReactModal
        isOpen
        className={styles.modalContent}
        contentLabel={intl.formatMessage({...messages.label})}
        overlayClassName={styles.modalOverlay}
        onRequestClose={props.onCancel}
    >
        <Box className={styles.body}>
          <Box>
            <div className={styles.loginTitle}>分享项目</div>
          </Box>
          <Box>
            <div className={styles.errorMsg}>{props.error}</div>
          </Box>
          <Box>
          <form >
            <div className={bstyle.formGroup}>
              <label htmlFor="instructions">项目介绍&使用指南</label>
              <textarea type="text" className={bstyle.formControl} name="instructions" id="instructions"
                value={props.instructions}
                rows="5"
                onChange={props.onInputChange}/>
            </div>
            <div className={bstyle.formGroup}>
            <label htmlFor="credits">致谢</label>
              <textarea className={bstyle.formControl} type="text" name="credits" id="credits"
                value={props.credits}
                onChange={props.onInputChange}/>
            </div>
          </form>
          </Box>
          <Box className={styles.buttonRow}>
            <button
                className={styles.okButton}
                title="Share"
                onClick={props.onShare}
            >
                <FormattedMessage
                    defaultMessage="分享"
                    description="Label for button to share"
                    id="gui.loginModal.share"
                />
            </button>
          </Box>
        </Box>
    </ReactModal>
);

ShareModal.propTypes = {
    intl: intlShape.isRequired,
    onCancel: PropTypes.func.isRequired,
    onShare: PropTypes.func.isRequired,
    onInputChange: PropTypes.func.isRequired
};

export default injectIntl(ShareModal);
