import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import {notify} from 'react-notify-toast';

import {
  saveProject
} from '../reducers/project';

import {
    updateSavingProgress
} from '../reducers/user';

import ProjectApi from '../api/project';


/**
 * Project saver component passes a saveProject function to its child.
 * It expects this child to be a function with the signature
 *     function (saveProject, props) {}
 * The component can then be used to attach project saving functionality
 * to any other component:
 *
 * <ProjectSaverCloud>{(saveProject, props) => (
 *     <MyCoolComponent
 *         onClick={saveProject}
 *         {...props}
 *     />
 * )}</ProjectSaverCloud>
 */
class ProjectSaverCloud extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'saveProject',
            'dataURItoBlob'
        ]);
    }
    dataURItoBlob (base64Data) {
        var byteString;
        if (base64Data.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(base64Data.split(',')[1]);
        else
            byteString = unescape(base64Data.split(',')[1]);
        var mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0];
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], {type: mimeString});
    };
    saveProject () {
        console.log('save project');
        if(this.props.projectName==''){
          notify.show('请输入项目名称', 'error');
          return;
        }

        //
        let screenshot = null;
        if (this.props.vm.runtime.renderer) {
          this.props.vm.runtime.renderer.draw(); // 这是绘制当前帧，然后才导出图片
          let base64Data = this.props.vm.runtime.renderer._gl.canvas.toDataURL();
          let blob = this.dataURItoBlob(base64Data);
          screenshot = blob;
          console.log(screenshot);
          //this.props.vm.runtime.renderer._gl.canvas.toBlob(function(blob){
          //  screenshot = blob;
          //  console.log(screenshot);
          //});
        }
        console.log(screenshot);
        //
        this.props.vm.saveProjectSb3().then(content => {
          let projectId = this.props.projectId;
          let projectName = this.props.projectName;
          let savingProgress = this.props.savingProgress;
          if(this.props.isSaveCopy && projectId > 0){//文件菜单，保存副本
            projectId = 0;
            projectName = projectName+'copy';
          }
          let timerId = setInterval(
              () => {savingProgress++;this.props.updateSavingProgress({savingProgress:savingProgress})},
              1000
          );
          var formData = new FormData();
          formData.append('id', projectId);
          formData.append('name', projectName);
          formData.append('content', content);
          if(screenshot!=null){
            console.log('add screenshot');
            formData.append('screenshot', screenshot);
          }
          if(projectId>0){
            ProjectApi.update(formData).then(res=>{
              notify.show('保存成功', 'success');
              clearInterval(timerId)
              this.props.updateSavingProgress({savingProgress:0})
            }).catch(err => {
              console.log(err);
              notify.show(err.message, 'error');
              clearInterval(timerId)
              this.props.updateSavingProgress({savingProgress:0})
            });
          }else{
            ProjectApi.create(formData).then(res=>{
              notify.show('保存成功', 'success');
              this.props.saveProject({id:res.id,name:projectName});

              clearInterval(timerId)
              this.props.updateSavingProgress({savingProgress:0})

            }).catch(err => {
              console.log(err);
              notify.show(err.message, 'error');
              clearInterval(timerId)
              this.props.updateSavingProgress({savingProgress:0})
            })
          }
        });
    }
    render () {
        const {
            /* eslint-disable no-unused-vars */
            children,
            vm,
            /* eslint-enable no-unused-vars */
            ...props
        } = this.props;
        return this.props.children(this.saveProject, props);
    }
}

ProjectSaverCloud.propTypes = {
    children: PropTypes.func,
    vm: PropTypes.shape({
        saveProjectSb3: PropTypes.func
    })
};

const mapStateToProps = state => ({
    vm: state.scratchGui.vm,
    projectId: state.scratchGui.project.id,
    projectName: state.scratchGui.project.name,
    savingProgress: state.scratchGui.user.savingProgress
});
const mapDispatchToProps = dispatch => ({
  saveProject: data => dispatch(saveProject(data)),
  updateSavingProgress: data => dispatch(updateSavingProgress(data)),
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectSaverCloud);
