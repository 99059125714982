const CHANGE_PROJECT_NAME = 'scratch-gui/project/CHANGE_NAME';
const SAVE_PROJECT = 'scratch-gui/project/SAVE_PROJECT';
const LOAD_PROJECT = 'scratch-gui/project/LOAD_PROJECT';
const NEW_PROJECT = 'scratch-gui/project/NEW_PROJECT';
const SHARE_PROJECT = 'scratch-gui/project/SHARE_PROJECT';

const initialState = {
  id: 0,
  name: '未命名项目-1',
  instructions: '',
  credits: ''
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case CHANGE_PROJECT_NAME:
        return Object.assign({}, state, {
            name: action.name
        });
    case SAVE_PROJECT:
        return Object.assign({}, state, {
            id: action.data.id,
            name: action.data.name
        });
    case LOAD_PROJECT:
        return Object.assign({}, state, {
            id: action.data.id,
            name: action.data.name,
            instructions: action.data.instructions,
            credits: action.data.credits
        });
    case NEW_PROJECT:
        return Object.assign({}, state, {
            id: action.data.id,
            name: action.data.name
        });
    case SHARE_PROJECT:
        return Object.assign({}, state, {
            instructions: action.data.instructions,
            credits: action.data.credits
        });
    default:
        return state;
    }
};

const changeProjectName = function (data) {
  return {
      type: CHANGE_PROJECT_NAME,
      name: data
  };
};
const saveProject = function (data) {
  return {
      type: SAVE_PROJECT,
      data: data
  };
};
const loadProject = function (data) {
  return {
      type: LOAD_PROJECT,
      data: data
  };
};
const newProject = function () {
  return {
      type: NEW_PROJECT,
      data: {id: 0, name: ''}
  };
};
const shareProject = function (data) {
  return {
      type: SHARE_PROJECT,
      data: data
  };
};

export {
    reducer as default,
    initialState as projectInitialState,
    changeProjectName,
    saveProject,
    loadProject,
    newProject,
    shareProject
};
