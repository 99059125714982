import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {defineMessages, injectIntl, intlShape} from 'react-intl';

import StageWrapper from '../../containers/stage-wrapper.jsx';
import Box from '../box/box.jsx';
import Modal from '../../containers/modal.jsx';
import Button from './count-button.jsx';
import CommentList from './comment-list.jsx';
import CommentInput from './comment-input.jsx';

import styles from './community-project.css';
import viewIcon from './view.svg';
import thumbsupIcon from './thumbsup.svg';
import favoriteIcon from './favorite.svg';
import commentIcon from './comment.svg';
import editIcon from './edit.svg';


class CommunityProjectComponent extends React.Component {
    constructor (props) {
        super(props);
    }
    componentDidUpdate (prevProps, prevState) {
      // this.scrollToTop();
    }


    scrollToTop () {
    }
    render () {
      const {
        title,
        id,
        onRequestClose,
        isRendererSupported,
        stageSize,
        viewCount,
        thumbsupCount,
        favCount,
        commentCount,
        commentList,
        onComment,
        onInputChange,
        onThumbsup,
        onFav,
        onEdit,
        isLoggedIn,
        isThumbsup,
        isFav,
        isEdit,
        isFullScreen,
        onSetStageFull,
        onSetStageUnFull,
        vm
      } = this.props;
        return (
            <Modal
                fullScreen
                contentLabel={title}
                id={id}
                onRequestClose={onRequestClose}
            >
                <div
                    className={styles.libraryScrollGrid}
                >
                <Box className={styles.body}>
                  <Box>
                    <StageWrapper
                        isFullScreen={isFullScreen}
                        isRtl = {false}
                        isRendererSupported={isRendererSupported}
                        stageSize={stageSize}
                        onSetStageFull = {onSetStageFull}
                        onSetStageUnFull = {onSetStageUnFull}
                        vm={vm}
                    />
                  </Box>
                  <Box className={styles.buttonRow}>
                    <Button iconSrc={viewIcon}>{viewCount}</Button>
                    <Button iconSrc={thumbsupIcon} disabled={(!isLoggedIn)||isThumbsup} onClick={onThumbsup}>{thumbsupCount}</Button>
                    <Button iconSrc={favoriteIcon} disabled={(!isLoggedIn)||isFav} onClick={onFav}>{favCount}</Button>
                    <Button iconSrc={commentIcon}>{commentCount}</Button>
                    {
                      isLoggedIn && isEdit ? (
                        <Button iconSrc={editIcon} className={styles.btnEdit} onClick={onEdit}>编辑</Button>
                      ):null
                    }
                  </Box>
                  <Box>
                    <CommentList data={commentList}/>
                    {isLoggedIn&&<CommentInput onInputChange={onInputChange} onComment={onComment}/>}
                  </Box>
                </Box>
                </div>
            </Modal>
        );
    }
}

CommunityProjectComponent.propTypes = {
    id: PropTypes.string.isRequired,
    onRequestClose: PropTypes.func,
    title: PropTypes.string.isRequired
};

export default injectIntl(CommunityProjectComponent);
