import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {notify} from 'react-notify-toast';

import decksLibraryContent from '../lib/libraries/decks/index.jsx';

import MyProjectsComponent from '../components/my-fav-projects/my-fav-projects.jsx';

import {connect} from 'react-redux';

import {
  openLoadingProject,
  closeLoadingProject,
  closeMyFavProjects
} from '../reducers/modals';

import {
    activateDeck
} from '../reducers/cards';

import {
    loadProject
} from '../reducers/project';

import ProjectApi from '../api/project';
import UserApi from '../api/user';

class MyFavProjects extends React.PureComponent {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleItemSelect',
            'onPageChange',
            'onSearchChange',
            'findUserFavProjects'
        ]);
        this.state = {
          decksLibraryThumbnailData: [],
          pageCount: 0,
          keywords: ''
        };
    }
    componentDidMount() {
      this.findUserFavProjects({curPage: 1, pageSize:60});
    }
    handleItemSelect (id, name,instructions,credits) {
      this.props.openLoadingState();
      ProjectApi.getFileContent({id}).then(res=>{
        console.log(res);
        //将Blob 对象转换成 ArrayBuffer
        var reader = new FileReader();
        reader.readAsArrayBuffer(res);
        reader.onload = () => this.props.vm.loadProject(reader.result)
            .then(() => {
                this.props.closeLoadingState();
                console.log(id);
                console.log(name);
                this.props.loadProject({id:0,name,instructions,credits});
            })
            .catch(error => {
              console.log(error);
              notify.show(error, 'success');
            });
      });
    }
    onPageChange (page){
      console.log(page);
      this.findUserFavProjects({keywords: this.state.keywords, curPage: page.selected + 1, pageSize:60});
    }
    findUserFavProjects (param) {
      ProjectApi.findUserFavProjects(param).then(res =>{
        let item_list = res.item_list;
        let projects = [];
        for(let i=0; i< item_list.length;i++){
          projects.push({
            rawURL: item_list[i].screenshot_url,
            id: item_list[i].id,
            name: item_list[i].name,
            instructions: item_list[i].instructions,
            credits: item_list[i].credits,
            featured: true
          })
        }
        // console.log(projects);
        this.setState({decksLibraryThumbnailData: projects, pageCount: res.page_total});
      })
    }
    onSearchChange (param){
      console.log(param)
      console.log('serach my fav projets');
      this.findUserFavProjects({keywords: param,curPage: 1, pageSize:60});
      this.setState({keywords: param});
    }
    render () {
        if (!this.props.visible) return null;
        return (
            <MyProjectsComponent
              id="myFavProjects"
              data={this.state.decksLibraryThumbnailData}
              filterable={false}
              title="我的收藏"
              visible={this.props.visible}
              onItemSelected={this.handleItemSelect}
              onRequestClose={this.props.onRequestClose}
              pageCount={this.state.pageCount}
              onPageChange={this.onPageChange}
              onSearchChange={this.onSearchChange}
            />
        );
    }
}

MyFavProjects.propTypes = {
    onActivateDeck: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func,
    visible: PropTypes.bool,
    vm: PropTypes.shape({
        loadProject: PropTypes.func
    })
};

const mapStateToProps = state => ({
    visible: state.scratchGui.modals.myFavProjects,
    vm: state.scratchGui.vm
});

const mapDispatchToProps = dispatch => ({
  closeLoadingState: () => dispatch(closeLoadingProject()),
  openLoadingState: () => dispatch(openLoadingProject()),
  onActivateDeck: id => dispatch(activateDeck(id)),
  onRequestClose: () => dispatch(closeMyFavProjects()),
  loadProject: data => dispatch(loadProject(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MyFavProjects);
