import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import {
    openLoadingProject,
    closeLoadingProject
} from '../reducers/modals';

import {
  changeProjectName,
  newProject
} from '../reducers/project';

import storage from '../lib/storage'
import ProjectApi from '../api/project';

/**
 * Project loader component passes a file input, load handler and props to its child.
 * It expects this child to be a function with the signature
 *     function (renderFileInput, loadProject, props) {}
 * The component can then be used to attach project loading functionality
 * to any other component:
 *
 * <ProjectLoader>{(renderFileInput, loadProject, props) => (
 *     <MyCoolComponent
 *         onClick={loadProject}
 *         {...props}
 *     >
 *         {renderFileInput()}
 *     </MyCoolComponent>
 * )}</ProjectLoader>
 */
class ProjectLoader extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'renderFileInput',
            'setFileInput',
            'handleChange',
            'handleClick'
        ]);
        this.state = {
            loadingError: false,
            errorMessage: ''
        };
    }
    handleChange (e) {
      console.log('file select');
        // Remove the hash if any (without triggering a hash change event or a reload)
        history.replaceState({}, document.title, '.');
        const reader = new FileReader();
        const thisFileInput = e.target;
        reader.onload = () => this.props.vm.loadProject(reader.result)
            .then(() => {
                this.props.closeLoadingState();
                // Reset the file input after project is loaded
                // This is necessary in case the user wants to reload a project
                thisFileInput.value = null;
            })
            .catch(error => {
                this.setState({loadingError: true, errorMessage: error});
            });
        if (thisFileInput.files) { // Don't attempt to load if no file was selected
          console.log('file read');
          this.props.newProject();
          let tmpName = thisFileInput.files[0].name;
          let stop = tmpName.lastIndexOf('.sb')>0 ? tmpName.lastIndexOf('.sb') : tmpName.length;
          this.props.changeProjectName(tmpName.substring(0, stop));
          this.props.openLoadingState();
          reader.readAsArrayBuffer(thisFileInput.files[0]);
        }
    }
    handleClick () {
      if(this.props.loadDefault){
        this.loadDefaultProject();
      }else{
        console.log('before select file');
        this.fileInput.click();
      }

    }
    loadDefaultProject() {
      this.props.openLoadingState();
      this.props.newProject();
      ProjectApi.getFileContent({id:100002}).then(res=>{
        var reader = new FileReader();
        reader.readAsArrayBuffer(res);
        reader.onload = () => {
          this.props.vm.loadProject(reader.result);
          this.props.closeLoadingState();
        }
      });
    }
    setFileInput (input) {
        this.fileInput = input;
    }
    renderFileInput () {
        return (
            <input
                name="fileinput"
                accept=".sb2,.sb3"
                ref={this.setFileInput}
                style={{display: 'none'}}
                type="file"
                onChange={this.handleChange}
            />
        );
    }
    render () {
        if (this.state.loadingError) {
            throw new Error(
                `Failed to load project from file: ${this.state.errorMessage}`);
        }
        const {
            /* eslint-disable no-unused-vars */
            children,
            closeLoadingState,
            openLoadingState,
            vm,
            /* eslint-enable no-unused-vars */
            ...props
        } = this.props;
        return this.props.children(this.renderFileInput, this.handleClick, props);
    }
}

ProjectLoader.propTypes = {
    children: PropTypes.func,
    closeLoadingState: PropTypes.func,
    openLoadingState: PropTypes.func,
    vm: PropTypes.shape({
        loadProject: PropTypes.func
    })
};

const mapStateToProps = state => ({
    vm: state.scratchGui.vm
});

const mapDispatchToProps = dispatch => ({
    closeLoadingState: () => dispatch(closeLoadingProject()),
    openLoadingState: () => dispatch(openLoadingProject()),
    changeProjectName: (newName) => dispatch(changeProjectName(newName)),
    newProject: () => dispatch(newProject())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectLoader);
