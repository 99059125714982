import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from 'react-modal';
import Box from '../box/box.jsx';
import {defineMessages, injectIntl, intlShape, FormattedMessage} from 'react-intl';

import Label from '../forms/label.jsx';
import Input from '../forms/input.jsx';
import ImageCode from './image-code.jsx';

import BufferedInputHOC from '../forms/buffered-input-hoc.jsx';

import {notify} from 'react-notify-toast';
import UserApi from '../../api/user';

import styles from './register-modal.css';

const BufferedInput = BufferedInputHOC(Input);

const messages = defineMessages({
    label: {
        id: 'gui.previewInfo.label',
        defaultMessage: 'Try Scratch 3.0',
        description: 'Scratch 3.0 modal label - for accessibility'
    }
});
const RegisterModal = ({intl, ...props}) => (
    <ReactModal
        isOpen
        className={styles.modalContent}
        contentLabel={intl.formatMessage({...messages.label})}
        overlayClassName={styles.modalOverlay}
        onRequestClose={props.onCancel}
    >
        <Box className={styles.body}>
          <Box>
            <div className={styles.loginTitle}>新用户注册</div>
          </Box>
          <Box>
            <div className={styles.errorMsg}>{props.error}</div>
          </Box>
          <Box>
            <div className={styles.inputGroup}>
                <label className={styles.inputLabel} htmlFor="username">用户名</label>
                <input className={styles.inputForm}  type="text" name="username" id="username" placeholder="请输入新用户名"
                  onChange={props.onInputChange}/>
            </div>
          </Box>
          <Box>
            <div className={styles.inputGroup}>
                <label className={styles.inputLabel} htmlFor="password">密码</label>
                <input className={styles.inputForm} type="password" name="password" id="password" placeholder="请输入密码"
                  onChange={props.onInputChange}/>
            </div>
          </Box>
          <Box>
            <div className={styles.inputGroup}>
                <label className={styles.inputLabel} htmlFor="passwordConfirm">密码确认</label>
                <input className={styles.inputForm} type="password" name="passwordConfirm" id="passwordConfirm" placeholder="请再次输入密码"
                  onChange={props.onInputChange}/>
            </div>
          </Box>
          <Box>
            <div className={styles.inputGroup}>
                <label className={styles.inputLabel} htmlFor="img_code">图形验证码</label>
                <input className={styles.inputForm}  type="text" name="img_code" id="img_code" placeholder="请输入图形验证码"
                  onChange={props.onInputChange}/>
                <ImageCode iconSrc={props.imageUrl} onClick={props.onImageCode}></ImageCode>
            </div>
          </Box>
          <Box>
            <div className={styles.inputGroup}>
                <label className={styles.inputLabel} htmlFor="mobile">手机号码</label>
                <input className={styles.inputForm}  type="text" name="mobile" id="mobile" placeholder="请输入手机号"
                  onChange={props.onInputChange}/>
                <button
                    className={styles.secondButton}
                    title="GetCode"
                    onClick={props.onGetCode}
                >
                  获取手机验证码
                </button>
            </div>
          </Box>
          <Box>
            <div className={styles.inputGroup}>
                <label className={styles.inputLabel} htmlFor="code">验证码</label>
                <input className={styles.inputForm}  type="text" name="code" id="code" placeholder="请输入收到的验证码"
                  onChange={props.onInputChange}/>
            </div>
          </Box>

          <Box className={styles.buttonRow}>
            <button
                className={styles.okButton}
                title="registger"
                onClick={props.onRegister}
            >
                <FormattedMessage
                    defaultMessage="注册"
                    description="Label for button to login"
                    id="gui.registerModal.register"
                />
            </button>
          </Box>
          <Box className={styles.buttonRow}>
            <button
                className={styles.noButton}
                title="Cancel"
                onClick={props.onCancel}
            >
                <FormattedMessage
                    defaultMessage="取消"
                    description="Label for button to login"
                    id="gui.registerModal.cancel"
                />
            </button>
          </Box>
        </Box>
    </ReactModal>
);

RegisterModal.propTypes = {
    intl: intlShape.isRequired,
    onCancel: PropTypes.func.isRequired,
    onRegister: PropTypes.func.isRequired,
    onInputChange: PropTypes.func.isRequired,
    onGetCode: PropTypes.func.isRequired
};

export default injectIntl(RegisterModal);
