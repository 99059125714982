import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {defineMessages, injectIntl, intlShape} from 'react-intl';

import LibraryItem from '../library-item-ky/library-item.jsx';
import Modal from '../../containers/modal.jsx';
import Divider from '../divider/divider.jsx';
import MyFilter from '../my-filter/filter.jsx';
import MyTagButton from '../../containers/my-tag-button.jsx';
import ReactPaginate from 'react-paginate';

import styles from './my-projects.css';
import styles2 from './rc-pagination.min.css';

import ProjectApi from '../../api/project';

const ALL_TAG_TITLE = 'All';
const tagListPrefix = [{title: ALL_TAG_TITLE}];

const messages = defineMessages({
    filterPlaceholder: {
        id: 'gui.library.filterPlaceholder',
        defaultMessage: 'Search',
        description: 'Placeholder text for library search field'
    }
});

class MyProjectsComponent extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleBlur',
            'handleFilterChange',
            'handleFilterClear',
            'handleFocus',
            'handleMouseEnter',
            'handleMouseLeave',
            'handleSelect',
            'setFilteredDataRef'
        ]);
        this.state = {
            selectedItem: null,
            filterQuery: '',
            selectedTag: ALL_TAG_TITLE.toLowerCase()
        };
    }
    componentDidUpdate (prevProps, prevState) {
        if (prevState.filterQuery !== this.state.filterQuery ||
            prevState.selectedTag !== this.state.selectedTag) {
            this.scrollToTop();
        }
    }
    handleBlur (id) {
        this.handleMouseLeave(id);
    }
    handleFocus (id) {
        this.handleMouseEnter(id);
    }
    handleSelect (id,name,instructions,credits) {
        this.props.onRequestClose();
        //this.props.onItemSelected(this.getFilteredData()[id]);
        // console.log('------------------');
        // console.log(instructions);
        this.props.onItemSelected(id,name,instructions,credits);
    }
    handleMouseEnter (id) {
        if (this.props.onItemMouseEnter) this.props.onItemMouseEnter(this.getFilteredData()[id]);
    }
    handleMouseLeave (id) {
        if (this.props.onItemMouseLeave) this.props.onItemMouseLeave(this.getFilteredData()[id]);
    }
    handleFilterChange (event) {
        this.setState({
          filterQuery: event.target.value
        });
        this.props.onSearchChange(event.target.value);
    }
    handleFilterClear () {
        this.setState({filterQuery: ''});
        this.props.onSearchChange('');
    }
    getFilteredData () {
        return this.props.data;
    }
    scrollToTop () {
        this.filteredDataRef.scrollTop = 0;
    }
    setFilteredDataRef (ref) {
        this.filteredDataRef = ref;
    }

    render () {
        return (
            <Modal
                fullScreen
                contentLabel={this.props.title}
                id={this.props.id}
                onRequestClose={this.props.onRequestClose}
            >
                <div className={styles.filterBar}>
                  <MyFilter
                      className={classNames(
                          styles.filterBarItem,
                          styles.filter
                      )}
                      filterQuery={this.state.filterQuery}
                      inputClassName={styles.filterInput}
                      placeholderText={this.props.intl.formatMessage(messages.filterPlaceholder)}
                      onChange={this.handleFilterChange}
                      onClear={this.handleFilterClear}
                  />
                </div>
                <div
                    className={classNames(styles.libraryScrollGrid, {
                        [styles.withFilterBar]: this.props.filterable || this.props.tags
                    })}
                    ref={this.setFilteredDataRef}
                >
                    {this.getFilteredData().map((dataItem, index) => {
                        const scratchURL = dataItem.md5 ?
                            `https://cdn.assets.scratch.mit.edu/internalapi/asset/${dataItem.md5}/get/` :
                            dataItem.rawURL;
                        return (
                            <LibraryItem
                                description={dataItem.description}
                                disabled={dataItem.disabled}
                                featured={dataItem.featured}
                                iconURL={scratchURL}
                                id={dataItem.id}
                                key={`item_${index}`}
                                name={dataItem.name}
                                instructions={dataItem.instructions}
                                credits={dataItem.credits}
                                onBlur={this.handleBlur}
                                onFocus={this.handleFocus}
                                onMouseEnter={this.handleMouseEnter}
                                onMouseLeave={this.handleMouseLeave}
                                onSelect={this.handleSelect}
                            />
                        );
                    })}
                </div>
                <div className={styles.pageWrapper}>
                <ReactPaginate
                  pageCount={this.props.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.props.onPageChange}
                  previousLabel={'上一页'}
                  nextLabel={'下一页'}
                  containerClassName={styles2.rcPagination}
                  pageClassName={styles2.rcPaginationItem}
                  disabledClassName={styles2.rcPaginationDisabled}
                  previousClassName={styles2.rcPaginationPrev}
                  nextClassName={styles2.rcPaginationNext}
                  activeClassName={styles2.rcPaginationItemActive}
                  previousLinkClassName={styles2.rcPaginationJumpPrev}
                  nextLinkClassName={styles2.rcPaginationJumpNext}
                  breakClassName={styles2.rcPaginationItem}
                />
                </div>
            </Modal>
        );
    }
}

MyProjectsComponent.propTypes = {
    data: PropTypes.arrayOf(
        /* eslint-disable react/no-unused-prop-types, lines-around-comment */
        // An item in the library
        PropTypes.shape({
            // @todo remove md5/rawURL prop from library, refactor to use storage
            md5: PropTypes.string,
            name: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.node
            ]).isRequired,
            rawURL: PropTypes.string
        })
        /* eslint-enable react/no-unused-prop-types, lines-around-comment */
    ),
    filterable: PropTypes.bool,
    id: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
    onItemMouseEnter: PropTypes.func,
    onItemMouseLeave: PropTypes.func,
    onItemSelected: PropTypes.func,
    onRequestClose: PropTypes.func,
    title: PropTypes.string.isRequired,
    onPageChange: PropTypes.func
};

MyProjectsComponent.defaultProps = {
    filterable: true
};

export default injectIntl(MyProjectsComponent);
