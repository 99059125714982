import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component }  from 'react';

import styles from './comment-input.css';

class CommentList extends Component {

  constructor (props) {
      super(props);
  }

  render() {
    const {
      data
    } = this.props;
    return (
      <div className="">{data.map((comment, i) => {
        return (
          <div key={i}>
            {comment.creator_name}：
            <p>{comment.content}</p>
          </div>
        )
      })}</div>
    )
  }
}

export default CommentList
