import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from 'react-modal';
import Box from '../box/box.jsx';
import {defineMessages, injectIntl, intlShape, FormattedMessage} from 'react-intl';

import Label from '../forms/label.jsx';
import Input from '../forms/input.jsx';

import BufferedInputHOC from '../forms/buffered-input-hoc.jsx';

import styles from './login-modal.css';

const BufferedInput = BufferedInputHOC(Input);

const messages = defineMessages({
    label: {
        id: 'gui.previewInfo.label',
        defaultMessage: 'Try Scratch 3.0',
        description: 'Scratch 3.0 modal label - for accessibility'
    }
});
const LoginModal = ({intl, ...props}) => (
    <ReactModal
        isOpen
        className={styles.modalContent}
        contentLabel={intl.formatMessage({...messages.label})}
        overlayClassName={styles.modalOverlay}
        onRequestClose={props.onTryIt}
    >
        <Box className={styles.body}>
          <Box>
            <div className={styles.loginTitle}>robotutor</div>
            {/*<div className={styles.loginTitle}>元创猫STEAM</div>*/}
          </Box>
          <Box>
            <div className={styles.errorMsg}>{props.error}</div>
          </Box>
          <Box>
            <div className={styles.inputGroup}>
                <label className={styles.inputLabel} htmlFor="username">用户名</label>
                <input className={styles.inputForm}  type="text" name="username" id="username" placeholder="请输入用户名"
                  onChange={props.onInputChange}/>
            </div>
          </Box>
          <Box>
            <div className={styles.inputGroup}>
                <label className={styles.inputLabel} htmlFor="password">密码</label>
                <input className={styles.inputForm} type="password" name="password" id="password" placeholder="请输入密码"
                  onChange={props.onInputChange}/>
            </div>
          </Box>
          <Box className={styles.buttonRow}>
            <button
                className={styles.okButton}
                title="login"
                onClick={props.onLogin}
            >
                <FormattedMessage
                    defaultMessage="登录"
                    description="Label for button to login"
                    id="gui.loginModal.login"
                />
            </button>
          </Box>
          <Box className={styles.buttonRow}>
            <button
                className={styles.secondButton}
                title="register"
                onClick={props.onViewRegister}
            >
                <FormattedMessage
                    defaultMessage="新用户注册"
                    description="Label for button to login"
                    id="gui.loginModal.register"
                />
            </button>
          </Box>
          <Box className={styles.buttonRow}>
            <a href="#" onClick={props.onResetPassword}> 忘记密码</a>
          </Box>
        </Box>
    </ReactModal>
);

LoginModal.propTypes = {
    intl: intlShape.isRequired,
    onCancel: PropTypes.func.isRequired,
    onTryIt: PropTypes.func.isRequired,
    onLogin: PropTypes.func.isRequired,
    onInputChange: PropTypes.func.isRequired,
    onViewRegister: PropTypes.func.isRequired,
    onResetPassword: PropTypes.func.isRequired
};

export default injectIntl(LoginModal);
