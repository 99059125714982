import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component }  from 'react';

import styles from './comment-input.css';
import bstyle from '../../css/bootstrap.min.css';

class CommentInput extends Component {
  constructor (props) {
      super(props);
  }



  render() {
    const {
      onInputChange,
      onComment
    } = this.props;
    return (
      <div className={styles.commentInput}>
        <form >
          <div className={bstyle.formGroup}>
            <label htmlFor="comment">添加一条评论</label>
            <textarea type="text" className={bstyle.formControl} name="comment" id="comment"
              rows="3"
              onChange={onInputChange}/>
          </div>
          <button
            type="button"
            className={classNames(bstyle.btn,bstyle.btnPrimary)}
            title="Share"
            onClick={onComment}
          >
          发布
          </button>
        </form>
      </div>
    )
  }
}
CommentInput.propTypes = {
    onComment: PropTypes.func.isRequired,
    onInputChange: PropTypes.func.isRequired
};

export default CommentInput
