import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {notify} from 'react-notify-toast';

import AudioEngine from 'scratch-audio';

import decksLibraryContent from '../lib/libraries/decks/index.jsx';

import CommunityComponent from '../components/community/community.jsx';

import {connect} from 'react-redux';

import {
  openLoadingProject,
  closeLoadingProject,
  closeCommunity,
  openCommunityProject
} from '../reducers/modals';

import {
    activateDeck
} from '../reducers/cards';

import {
    loadProject
} from '../reducers/community';

import {
    updateModal
} from '../reducers/user';

import ProjectApi from '../api/project';

class Community extends React.PureComponent {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleItemSelect'
        ]);
        this.state = {
          decksLibraryThumbnailData: []
        };
    }
    componentDidMount() {
      //
      if (!this.props.vm.initialized){
        this.audioEngine = new AudioEngine();
        this.props.vm.attachAudioEngine(this.audioEngine);
        this.props.vm.setCompatibilityMode(true);
        this.props.vm.start();
        this.props.vm.initialized = true;
        this.props.vm.setLocale(this.props.locale, this.props.messages);
      }
      //
      ProjectApi.findSharedProjects().then(res =>{
        let item_list = res.item_list;
        let projects = [];
        for(let i=0; i< item_list.length;i++){
          projects.push({
            rawURL: item_list[i].screenshot_url,
            id: item_list[i].id,
            name: item_list[i].name,
            instructions: item_list[i].instructions,
            credits: item_list[i].credits,
            featured: true
          })
        }
        //console.log(projects);
        this.setState({decksLibraryThumbnailData: projects});
      })
    }
    handleItemSelect (id, name) {
      console.log('====');
      console.log('+'+id);
      ProjectApi.addViewCount({id:id}).then(res => {
        //
      }).catch( err => {
        console.log(err.message);
      });

      this.props.openLoadingState();
      ProjectApi.getFileContent({id}).then(res=>{
        // console.log(res);
        //将Blob 对象转换成 ArrayBuffer
        var reader = new FileReader();
        reader.readAsArrayBuffer(res);
        reader.onload = () => {
          console.log('====');
          console.log(reader.result);
          this.props.closeLoadingState();
          this.props.loadProject({id,name,data: reader.result});
          this.props.onOpenCommunityProject();
          this.props.updateModal({curModal: ''})
        }
      });

    }
    render () {
        if (!this.props.visible) return null;
        return (
            <CommunityComponent
              id="community"
              data={this.state.decksLibraryThumbnailData}
              filterable={false}
              title="分享的项目"
              visible={this.props.visible}
              onItemSelected={this.handleItemSelect}
              onRequestClose={this.props.onRequestClose}
            />
        );
    }
}

Community.propTypes = {
    onActivateDeck: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func,
    visible: PropTypes.bool,
    vm: PropTypes.shape({
        loadProject: PropTypes.func
    })
};

const mapStateToProps = state => ({
    visible: state.scratchGui.modals.community,
    vm: state.scratchGui.community.vm,
    locale: state.locales.locale,
    messages: state.locales.messages
});

const mapDispatchToProps = dispatch => ({
  closeLoadingState: () => dispatch(closeLoadingProject()),
  openLoadingState: () => dispatch(openLoadingProject()),
  onActivateDeck: id => dispatch(activateDeck(id)),
  onRequestClose: () => dispatch(closeCommunity()),
  loadProject: data => dispatch(loadProject(data)),
  onOpenCommunityProject: () => dispatch(openCommunityProject()),
  updateModal: data => dispatch(updateModal(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Community);
