exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".comment-input_comment-input_2KESV {\n\n}\n", ""]);

// exports
exports.locals = {
	"comment-input": "comment-input_comment-input_2KESV",
	"commentInput": "comment-input_comment-input_2KESV"
};