import sessionstorage from '../utils/sessionstorage'

const USER_LOGIN = 'login';
const USER_REGISTER = 'register';
const USER_GET_CUR = 'getCurUserInfo';
const USER_LOGOUT = 'logout';
const USER_SAVING_PROGRESS = 'savingProgress';
const USER_UPDATE_MODAL = 'updateModal';

let token =  sessionstorage.getItem('token')?sessionstorage.getItem('token'):'';
let isLoggedIn =  sessionstorage.getItem('token')?true:false;
let username = sessionstorage.getItem('token')?sessionstorage.getItem('username'):''
let type = sessionstorage.getItem('type')?sessionstorage.getItem('type'):3
let curModal = sessionstorage.getItem('curModal')?sessionstorage.getItem('type'):''
const initialState = {
    isLoggedIn: isLoggedIn,
    token: token,
    username: username,
    savingProgress: 0,
    type: type,
    curModal: curModal
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case USER_LOGIN:
        sessionstorage.setItem('token', action.data.token);
        sessionstorage.setItem('isLoggedIn', action.data.isLoggedIn);
        sessionstorage.setItem('username', action.data.username);
        sessionstorage.setItem('type', action.data.type);
        return Object.assign({}, state, {
            isLoggedIn: action.data.isLoggedIn,
            token: action.data.token,
            username: action.data.username,
            type: action.data.type
        });
    case USER_LOGOUT:
        sessionstorage.setItem('token', '');
        return Object.assign({}, state, {
            isLoggedIn: false,
            token: '',
            username: '',
            savingProgress: 0,
            type: 3
        });
    case USER_GET_CUR:
        return Object.assign({}, state, {
            [action.modal]: false
        });
    case USER_SAVING_PROGRESS:
        return Object.assign({}, state, {
            savingProgress: action.data.savingProgress
        });
    case USER_UPDATE_MODAL:
        return Object.assign({}, state, {
            curModal: action.data.curModal
        });
    default:
        return state;
    }
};

const userLogin = function (data) {
    return {
        type: USER_LOGIN,
        data: data
    };
};

const userLogout = function () {
    return {
        type: USER_LOGOUT,
        data: initialState
    };
};
const updateSavingProgress = function (data) {
  console.log(data)
    return {
        type: USER_SAVING_PROGRESS,
        data: data
    };
};
const updateModal = function (data) {
    return {
        type: USER_UPDATE_MODAL,
        data: data
    };
};

export {
    reducer as default,
    initialState as userInitialState,
    userLogin,
    userLogout,
    updateSavingProgress,
    updateModal
};
