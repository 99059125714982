import classNames from 'classnames';
import {connect} from 'react-redux';
import {defineMessages, FormattedMessage, injectIntl, intlShape} from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';

import {notify} from 'react-notify-toast';
import Circle from 'react-circle';

import Box from '../box/box.jsx';
import Button from '../button/button.jsx';
import {ComingSoonTooltip} from '../coming-soon/coming-soon.jsx';
import Divider from '../divider/divider.jsx';
import LanguageSelector from '../../containers/language-selector.jsx';
import ProjectLoader from '../../containers/project-loader.jsx';
import Menu from '../../containers/menu.jsx';
import {MenuItem, MenuSection} from '../menu/menu.jsx';
import ProjectSaver from '../../containers/project-saver.jsx';
import ProjectSaverCloud from '../../containers/project-saver-cloud.jsx';

import {
  openTipsLibrary,
  openLogin,
  openMyProjects,
  openMyFavProjects,
  openShare,
  openCommunity,
  openProjectStudent
} from '../../reducers/modals';
import {setPlayer} from '../../reducers/mode';
import {
    openFileMenu,
    closeFileMenu,
    fileMenuOpen,
    openEditMenu,
    closeEditMenu,
    editMenuOpen,
    openLanguageMenu,
    closeLanguageMenu,
    languageMenuOpen,
    userMenuOpen,
    openUserMenu,
    closeUserMenu
} from '../../reducers/menus';

import {
  userLogout
} from '../../reducers/user';

import {
  changeProjectName
} from '../../reducers/project';

import styles from './menu-bar.css';

import mystuffIcon from './icon--mystuff.png';
import feedbackIcon from './icon--feedback.svg';
import profileIcon from './icon--profile.png';
import communityIcon from './icon--see-community.svg';
import dropdownCaret from './dropdown-caret.svg';
import languageIcon from '../language-selector/language-icon.svg';

//import scratchLogo from './scratch-logo.svg';
import scratchLogo from './robotutor-logo3.png';
//import scratchLogo from './ycm-logo.png';

// import helpIcon from './icon--help.svg';
import helpIcon from '../../lib/assets/icon--tutorials.svg';

const ariaMessages = defineMessages({
    language: {
        id: 'gui.menuBar.LanguageSelector',
        defaultMessage: 'language selector',
        description: 'accessibility text for the language selection menu'
    },
    tutorials: {
        id: 'gui.menuBar.tutorialsLibrary',
        defaultMessage: 'Tutorials',
        description: 'accessibility text for the tutorials button'
    }
});

const MenuBarItemTooltip = ({
    children,
    className,
    enable,
    id,
    place = 'bottom'
}) => {
    if (enable) {
        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        );
    }
    return (
        <ComingSoonTooltip
            className={classNames(styles.comingSoon, className)}
            place={place}
            tooltipClassName={styles.comingSoonTooltip}
            tooltipId={id}
        >
            {children}
        </ComingSoonTooltip>
    );
};

MenuBarItemTooltip.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    enable: PropTypes.bool,
    id: PropTypes.string,
    place: PropTypes.oneOf(['top', 'bottom', 'left', 'right'])
};

const MenuItemTooltip = ({id, children, className}) => (
    <ComingSoonTooltip
        className={classNames(styles.comingSoon, className)}
        place="right"
        tooltipClassName={styles.comingSoonTooltip}
        tooltipId={id}
    >
        {children}
    </ComingSoonTooltip>
);

MenuItemTooltip.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    id: PropTypes.string
};

const MenuBarMenu = ({
    children,
    onRequestClose,
    open,
    place = 'right'
}) => (
    <Menu
        className={styles.menu}
        open={open}
        place={place}
        onRequestClose={onRequestClose}
    >
        {children}
    </Menu>
);

MenuBarMenu.propTypes = {
    children: PropTypes.node,
    onRequestClose: PropTypes.func,
    open: PropTypes.bool,
    place: PropTypes.oneOf(['left', 'right'])
};


const MenuBar = props => (
    <Box className={styles.menuBar}>
        <div className={styles.mainMenu}>
            <div className={styles.fileGroup}>
                <div className={classNames(styles.menuBarItem)}>
                    <img
                        alt="Scratch"
                        className={styles.scratchLogo}
                        draggable={false}
                        src={scratchLogo}
                    />
                </div>
                <div
                    className={classNames(styles.menuBarItem, styles.hoverable, styles.languageMenu)}
                >
                    <div className={styles.menuBarUser}>
                        <img
                            className={styles.languageIcon}
                            src={languageIcon}
                        />
                        <img
                            className={styles.languageCaret}
                            src={dropdownCaret}
                        />
                    </div>
                    <LanguageSelector label={props.intl.formatMessage(ariaMessages.language)} />
                </div>
                <div
                    className={classNames(styles.menuBarItem, styles.hoverable, {
                        [styles.active]: props.fileMenuOpen
                    })}
                    onMouseUp={props.onClickFile}
                >
                    <div className={classNames(styles.fileMenu)}>
                        <FormattedMessage
                            defaultMessage="File"
                            description="Text for file dropdown menu"
                            id="gui.menuBar.file"
                        />
                    </div>
                    <MenuBarMenu
                        open={props.fileMenuOpen}
                        onRequestClose={props.onRequestCloseFile}
                    >
                        <ProjectLoader
                          loadDefault={true}
                        >{(renderFileInput, loadProject, loadProps) => (
                            <MenuItem
                                onClick={loadProject}
                                {...loadProps}
                            >
                                <FormattedMessage
                                    defaultMessage="新建项目"
                                    description="Menu bar item for creating a new project"
                                    id="gui.menuBar.new1"
                                />
                            </MenuItem>
                        )}</ProjectLoader>
                          <ProjectSaverCloud
                            isLoggedIn={props.isLoggedIn}
                            isSaveCopy={true}
                            >{(saveProject, saveProps) => (
                              <MenuItem
                                onClick={() => {saveProps.isLoggedIn ? saveProject():props.onOpenLogin()}}
                                >
                                <FormattedMessage
                                    defaultMessage="Save as a copy"
                                    description="Menu bar item for saving as a copy"
                                    id="gui.menuBar.saveAsCopy"
                                />
                              </MenuItem>
                          )}</ProjectSaverCloud>
                        <MenuSection>
                            <ProjectLoader>{(renderFileInput, loadProject, loadProps) => (
                                <MenuItem
                                    onClick={loadProject}
                                    {...loadProps}
                                >
                                    <FormattedMessage
                                        defaultMessage="从我的电脑载入"
                                        description="Menu bar item for uploading a project from your computer"
                                        //id="gui.menuBar.uploadFromComputer"
                                        id="gui.menuBar.loadFromComputer"
                                    />
                                    {renderFileInput()}
                                </MenuItem>
                            )}</ProjectLoader>
                            <ProjectSaver
                              >{(saveProject, saveProps) => (
                                <MenuItem
                                    onClick={saveProject}
                                    {...saveProps}
                                >
                                    <FormattedMessage
                                        defaultMessage="保存到我的电脑"
                                        description="Menu bar item for downloading a project to your computer"
                                        //id="gui.menuBar.downloadToComputer"
                                        id="gui.menuBar.saveToComputer"
                                    />
                                </MenuItem>
                            )}</ProjectSaver>
                        </MenuSection>
                    </MenuBarMenu>
                </div>
                <div
                    className={classNames(styles.menuBarItem, styles.hoverable, {
                        [styles.active]: props.editMenuOpen
                    })}
                    onMouseUp={props.onClickEdit}
                    //onMouseOver={props.onClickEdit}
                    //onMouseLeave={props.onRequestCloseEdit}
                >
                    <div className={classNames(styles.editMenu)}>
                        <FormattedMessage
                            defaultMessage="Edit"
                            description="Text for edit dropdown menu"
                            id="gui.menuBar.edit"
                        />
                    </div>
                    <MenuBarMenu
                        open={props.editMenuOpen}
                        onRequestClose={props.onRequestCloseEdit}
                    >
                        <MenuItem
                        onClick={() => {props.workspace.undo();}}
                        >
                            <FormattedMessage
                                defaultMessage="撤销"
                                description="Menu bar item for undoing"
                                id="gui.menuBar.undo"
                            />
                        </MenuItem>
                        <MenuItem
                        onClick={() => {props.workspace.undo(true);}}
                        >
                            <FormattedMessage
                                defaultMessage="重做"
                                description="Menu bar item for redoing"
                                id="gui.menuBar.redo"
                            />
                        </MenuItem>

                        {/*<MenuSection>
                            <MenuItemTooltip id="turbo">
                                <MenuItem>
                                    <FormattedMessage
                                        defaultMessage="Turbo mode"
                                        description="Menu bar item for toggling turbo mode"
                                        id="gui.menuBar.turboMode"
                                    />
                                </MenuItem>
                            </MenuItemTooltip>
                        </MenuSection> */}
                    </MenuBarMenu>
                </div>
            </div>
            <Divider className={classNames(styles.divider)} />
            <div className={classNames(styles.menuBarItem)}>
                <input
                    value={props.projectName}
                    className={classNames(styles.titleField)}
                    placeholder="未命名项目-1"
                    onChange={props.onChangeProjectName}
                />
            </div>
            <div className={classNames(styles.menuBarItem)}>
              <ProjectSaverCloud
                isLoggedIn={props.isLoggedIn}
                >{(saveProject, saveProps) => (
                  <Button className={classNames(styles.shareButton)}
                    onClick={() => {saveProps.isLoggedIn ? (props.savingProgress === 0?saveProject():''):props.onOpenLogin()}}
                    >
                      <FormattedMessage
                          defaultMessage="保存"
                          description="Label for project share button"
                          id="gui.menuBar.save"
                      />
                  </Button>
              )}</ProjectSaverCloud>
            </div>
            <div className={classNames(styles.menuBarItem)}>
              {props.savingProgress?
                <Circle  size="30" progress={props.savingProgress} textColor="#fff"
                  showPercentageSymbol={false}
                  textStyle={{
                    font: 'bold 7rem Helvetica, Arial, sans-serif' // CSSProperties: Custom styling for percentage.
                  }}
                />:
                <div></div>
              }
            </div>
            <div className={classNames(styles.menuBarItem)}>
                <Button className={classNames(styles.shareButton)}
                  onClick={()=> {props.isLoggedIn ? props.onOpenShare(props.projectId) : props.onOpenLogin()}}
                  >
                    <FormattedMessage
                        defaultMessage="Share"
                        description="Label for project share button"
                        id="gui.menuBar.share"
                    />
                </Button>
            </div>
            <div className={classNames(styles.menuBarItem, styles.communityButtonWrapper)}>
                {props.enableCommunity ?
                    <Button
                        className={classNames(styles.communityButton)}
                        iconClassName={styles.communityButtonIcon}
                        iconSrc={communityIcon}
                        onClick={()=> {props.onSeeCommunity()}}
                    >
                        <FormattedMessage
                            defaultMessage="查看社区"
                            description="Label for see community button"
                            id="gui.menuBar.seeCommunity"
                        />
                    </Button> :
                    <MenuBarItemTooltip id="community-button">
                        <Button
                            className={classNames(styles.communityButton)}
                            iconClassName={styles.communityButtonIcon}
                            iconSrc={communityIcon}
                        >
                            <FormattedMessage
                                defaultMessage="查看社区"
                                description="Label for see community button"
                                id="gui.menuBar.seeCommunity"
                            />
                        </Button>
                    </MenuBarItemTooltip>
                }
            </div>
        </div>
        {/* <div className={classNames(styles.menuBarItem, styles.feedbackButtonWrapper)}>
            <a
                className={styles.feedbackLink}
                href="https://scratch.mit.edu/discuss/topic/299791/"
                rel="noopener noreferrer"
                target="_blank"
            >
                <Button
                    className={styles.feedbackButton}
                    iconSrc={feedbackIcon}
                >
                    <FormattedMessage
                        defaultMessage="Give Feedback"
                        description="Label for feedback form modal button"
                        id="gui.menuBar.giveFeedback"
                    />
                </Button>
            </a>
        </div>*/}
        <div className={styles.accountInfoWrapper}>
            <div
                aria-label={props.intl.formatMessage(ariaMessages.tutorials)}
                className={classNames(styles.menuBarItem, styles.hoverable)}
                onClick={props.onOpenTipLibrary}
            >
                <img
                    className={styles.helpIcon}
                    src={helpIcon}
                />
                <FormattedMessage {...ariaMessages.tutorials} />
            </div>
            {/*<MenuBarItemTooltip id="mystuff">
                <div
                    className={classNames(
                        styles.menuBarItem,
                        styles.hoverable,
                        styles.mystuffButton
                    )}
                >
                    <img
                        className={styles.mystuffIcon}
                        src={mystuffIcon}
                    />
                </div>
            </MenuBarItemTooltip>*/}
            {
              props.isLoggedIn ? (
                <div
                    className={classNames(styles.menuBarItem, styles.hoverable, {
                        [styles.active]: props.userMenuOpen
                    })}
                    onMouseUp={props.onClickUser}
                    //onMouseOver={props.onClickUser}
                    //onMouseLeave={props.onRequestCloseUser}
                >
                  <div className={styles.menuBarUser}>
                      <img
                          className={styles.profileIcon}
                          src={profileIcon}
                      />
                      <span>
                          {props.username}
                      </span>
                      <img
                          className={styles.dropdownCaretIcon}
                          src={dropdownCaret}
                      />
                  </div>
                    <MenuBarMenu
                        open={props.userMenuOpen}
                        onRequestClose={props.onRequestCloseUser}
                    >
                    <MenuItem
                      onClick={props.onOpenMyProjects}
                    >
                        <FormattedMessage
                            defaultMessage="我的项目"
                            description="Menu bar item for listing my projects"
                            id="gui.menuBar.myProjects"
                        />
                    </MenuItem>
                    { props.type === 2 ? (
                        <MenuItem
                          onClick={props.onOpenStudentProjects}
                        >
                            <FormattedMessage
                                defaultMessage="学生项目"
                                description="Menu bar item for listing student projects"
                                id="gui.menuBar.studentProjects"
                            />
                        </MenuItem>
                      ):null
                    }
                    <MenuItem
                      onClick={props.onOpenMyFavProjects}
                    >
                        <FormattedMessage
                            defaultMessage="我的收藏"
                            description="Menu bar item for listing fav favorites"
                            id="gui.menuBar.myFavorites"
                        />
                    </MenuItem>
                    <MenuItem
                      onClick={props.onLogout}
                    >
                        <FormattedMessage
                            defaultMessage="退出登录"
                            description="Menu bar item for logging out"
                            id="gui.menuBar.loggout"
                        />
                    </MenuItem>
                  </MenuBarMenu>
                </div>
              ):(
                <div
                    className={classNames(
                        styles.menuBarItem,
                        styles.hoverable,
                        styles.accountNavMenu
                    )}
                    onClick={props.onOpenLogin}
                >
                    <img
                        className={styles.profileIcon}
                        src={profileIcon}
                    />
                    <span>
                        {'登录'}
                    </span>
                    <img
                        className={styles.dropdownCaretIcon}
                        src={dropdownCaret}
                    />
                </div>
              )
            }
        </div>
    </Box>
);

MenuBar.propTypes = {
    editMenuOpen: PropTypes.bool,
    enableCommunity: PropTypes.bool,
    fileMenuOpen: PropTypes.bool,
    intl: intlShape,
    languageMenuOpen: PropTypes.bool,
    onClickEdit: PropTypes.func,
    onClickFile: PropTypes.func,
    onClickLanguage: PropTypes.func,
    onOpenTipLibrary: PropTypes.func,
    onRequestCloseEdit: PropTypes.func,
    onRequestCloseFile: PropTypes.func,
    onRequestCloseLanguage: PropTypes.func,
    onSeeCommunity: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    onClickUser: PropTypes.func,
    userMenuOpen: PropTypes.bool,
    onOpenMyProjects: PropTypes.func,
    onOpenMyFavProjects: PropTypes.func,
    onOpenStudentProjects: PropTypes.func
};

const mapStateToProps = state => ({
    fileMenuOpen: fileMenuOpen(state),
    editMenuOpen: editMenuOpen(state),
    languageMenuOpen: languageMenuOpen(state),
    isLoggedIn: state.scratchGui.user.isLoggedIn,
    username: state.scratchGui.user.username,
    type: state.scratchGui.user.type,
    userMenuOpen: userMenuOpen(state),
    projectId: state.scratchGui.project.id,
    projectName: state.scratchGui.project.name,
    workspace: state.scratchGui.workspace,
    savingProgress: state.scratchGui.user.savingProgress,
});
const mapDispatchToProps = (dispatch) => ({
    onOpenTipLibrary: () => dispatch(openTipsLibrary()),
    onClickFile: () => dispatch(openFileMenu()),
    onRequestCloseFile: () => dispatch(closeFileMenu()),
    onClickEdit: () => dispatch(openEditMenu()),
    onRequestCloseEdit: () => dispatch(closeEditMenu()),
    onClickLanguage: () => dispatch(openLanguageMenu()),
    onRequestCloseLanguage: () => dispatch(closeLanguageMenu()),
    onSeeCommunity: () => dispatch(openCommunity()),
    onOpenLogin: () => dispatch(openLogin()),
    onLogout: () =>{
      dispatch(userLogout());
    },
    onClickUser: () => dispatch(openUserMenu()),
    onRequestCloseUser: () => dispatch(closeUserMenu()),
    onChangeProjectName: (e) => {
      dispatch(changeProjectName(e.target.value))
    },
    onOpenMyProjects: () => dispatch(openMyProjects()),
    onOpenMyFavProjects: () => dispatch(openMyFavProjects()),
    onOpenStudentProjects: () => dispatch(openProjectStudent()),
    onOpenShare: (projectId) => {
      if(projectId <=0 ){
        notify.show('请先保存项目','error');
      }else{
        dispatch(openShare());
      }

    }
});

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(MenuBar));
