import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import tabletFullScreen from '../lib/tablet-full-screen';

import ShareModalComponent from '../components/share-modal/share-modal.jsx';
import BrowserModalComponent from '../components/browser-modal/browser-modal.jsx';
import supportedBrowser from '../lib/supported-browser';

import {notify} from 'react-notify-toast';
import ProjectApi from '../api/project';
import sessionstorage from '../utils/sessionstorage'

import {
    closeShare
} from '../reducers/modals';

import {
    shareProject
} from '../reducers/project';

class ShareModal extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleCancel',
            'handleShare',
            'handleInputChange'
        ]);

        this.state = {
            instructions: this.props.instructions,
            credits: this.props.credits,
            error: ''
        };
    }
    handleInputChange(event) {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;

      this.setState({
        [name]: value
      });
    }
    handleShare () {
        const {instructions, credits} = this.state;
        ProjectApi.share({id:this.props.projectId,instructions,credits}).then(res => {
          notify.show('分享成功', 'success');
          this.props.onShare({instructions: instructions, credits: credits});
        }).catch( err => {
          this.setState({error: err.message});
        });

    }

    handleCancel () {
      this.props.onCancel();
    }
    render () {
        return (supportedBrowser() ?
            <ShareModalComponent
              error={this.state.error}
              instructions={this.state.instructions}
              credits={this.state.credits}
              onCancel={this.handleCancel}
              onShare={this.handleShare}
              onInputChange={this.handleInputChange}
            /> :
            <BrowserModalComponent
                onBack={this.handleCancel}
            />
        );
    }
}

ShareModal.propTypes = {
    onShare: PropTypes.func
};

const mapStateToProps = state => ({
    projectId: state.scratchGui.project.id,
    instructions: state.scratchGui.project.instructions,
    credits: state.scratchGui.project.credits
});

const mapDispatchToProps = dispatch => ({
    onCancel: () => {
        dispatch(closeShare());
    },
    onShare: (data) => {
      dispatch(closeShare());
      dispatch(shareProject(data));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShareModal);
