const OPEN_MODAL = 'scratch-gui/modals/OPEN_MODAL';
const CLOSE_MODAL = 'scratch-gui/modals/CLOSE_MODAL';

const MODAL_BACKDROP_LIBRARY = 'backdropLibrary';
const MODAL_CAMERA_CAPTURE = 'cameraCapture';
const MODAL_COSTUME_LIBRARY = 'costumeLibrary';
const MODAL_EXTENSION_LIBRARY = 'extensionLibrary';
const MODAL_LOADING_PROJECT = 'loadingProject';
const MODAL_TELEMETRY = 'telemetryModal';
const MODAL_SOUND_LIBRARY = 'soundLibrary';
const MODAL_SPRITE_LIBRARY = 'spriteLibrary';
const MODAL_SOUND_RECORDER = 'soundRecorder';
const MODAL_CONNECTION = 'connectionModal';
const MODAL_TIPS_LIBRARY = 'tipsLibrary';

const MODAL_LOGIN = 'login';
const MODAL_REGISTER = 'register';
const MODAL_MY_PROJECTS = 'myProjects';
const MODAL_SHARE = 'share';
const MODAL_COMMUNITY = 'community';
const MODAL_COMMUNITY_PROJECT = 'communityProject';
const MODAL_RESET_PASSWORD = 'resetPassword';
const MODAL_PROJECT_STUDENT = 'projectStudent';
const MODAL_MY_FAV_PROJECTS = 'myFavProjects';


const initialState = {
    [MODAL_BACKDROP_LIBRARY]: false,
    [MODAL_CAMERA_CAPTURE]: false,
    [MODAL_COSTUME_LIBRARY]: false,
    [MODAL_EXTENSION_LIBRARY]: false,
    [MODAL_LOADING_PROJECT]: false,
    [MODAL_TELEMETRY]: false,
    [MODAL_SOUND_LIBRARY]: false,
    [MODAL_SPRITE_LIBRARY]: false,
    [MODAL_SOUND_RECORDER]: false,
    [MODAL_CONNECTION]: false,
    [MODAL_TIPS_LIBRARY]: false,

    [MODAL_LOGIN]: false,
    [MODAL_REGISTER]: false,
    [MODAL_MY_PROJECTS]: false,
    [MODAL_SHARE]: false,
    [MODAL_COMMUNITY]: false,
    [MODAL_COMMUNITY_PROJECT]: false,
    [MODAL_RESET_PASSWORD]: false,
    [MODAL_PROJECT_STUDENT]: false,
    [MODAL_MY_FAV_PROJECTS]: false
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case OPEN_MODAL:
        return Object.assign({}, state, {
            [action.modal]: true
        });
    case CLOSE_MODAL:
        return Object.assign({}, state, {
            [action.modal]: false
        });
    default:
        return state;
    }
};
const openModal = function (modal) {
    return {
        type: OPEN_MODAL,
        modal: modal
    };
};
const closeModal = function (modal) {
    return {
        type: CLOSE_MODAL,
        modal: modal
    };
};
const openBackdropLibrary = function () {
    return openModal(MODAL_BACKDROP_LIBRARY);
};
const openCameraCapture = function () {
    return openModal(MODAL_CAMERA_CAPTURE);
};
const openCostumeLibrary = function () {
    return openModal(MODAL_COSTUME_LIBRARY);
};
const openExtensionLibrary = function () {
    return openModal(MODAL_EXTENSION_LIBRARY);
};
const openLoadingProject = function () {
    return openModal(MODAL_LOADING_PROJECT);
};
const openTelemetryModal = function () {
    return openModal(MODAL_TELEMETRY);
};
const openSoundLibrary = function () {
    return openModal(MODAL_SOUND_LIBRARY);
};
const openSpriteLibrary = function () {
    return openModal(MODAL_SPRITE_LIBRARY);
};
const openSoundRecorder = function () {
    return openModal(MODAL_SOUND_RECORDER);
};
const openConnectionModal = function () {
    return openModal(MODAL_CONNECTION);
};
const openTipsLibrary = function () {
    return openModal(MODAL_TIPS_LIBRARY);
};
const openLogin = function () {
    return openModal(MODAL_LOGIN);
};
const openRegister = function () {
    return openModal(MODAL_REGISTER);
};
const openMyProjects = function () {
    return openModal(MODAL_MY_PROJECTS);
};
const openMyFavProjects = function () {
    return openModal(MODAL_MY_FAV_PROJECTS);
};
const openShare = function () {
    return openModal(MODAL_SHARE);
};
const openCommunity = function () {
    return openModal(MODAL_COMMUNITY);
};
const openCommunityProject = function () {
    return openModal(MODAL_COMMUNITY_PROJECT);
};
const openResetPassword = function () {
    return openModal(MODAL_RESET_PASSWORD);
};
const openProjectStudent = function () {
    return openModal(MODAL_PROJECT_STUDENT);
};

const closeBackdropLibrary = function () {
    return closeModal(MODAL_BACKDROP_LIBRARY);
};
const closeCameraCapture = function () {
    return closeModal(MODAL_CAMERA_CAPTURE);
};
const closeCostumeLibrary = function () {
    return closeModal(MODAL_COSTUME_LIBRARY);
};
const closeExtensionLibrary = function () {
    return closeModal(MODAL_EXTENSION_LIBRARY);
};
const closeLoadingProject = function () {
    return closeModal(MODAL_LOADING_PROJECT);
};
const closeTelemetryModal = function () {
    return closeModal(MODAL_TELEMETRY);
};
const closeSpriteLibrary = function () {
    return closeModal(MODAL_SPRITE_LIBRARY);
};
const closeSoundLibrary = function () {
    return closeModal(MODAL_SOUND_LIBRARY);
};
const closeSoundRecorder = function () {
    return closeModal(MODAL_SOUND_RECORDER);
};
const closeTipsLibrary = function () {
    return closeModal(MODAL_TIPS_LIBRARY);
};
const closeConnectionModal = function () {
    return closeModal(MODAL_CONNECTION);
};
const closeLogin = function () {
    return closeModal(MODAL_LOGIN);
};
const closeRegister = function () {
    return closeModal(MODAL_REGISTER);
};
const closeMyProjects = function () {
    return closeModal(MODAL_MY_PROJECTS);
};
const closeMyFavProjects = function () {
    return closeModal(MODAL_MY_FAV_PROJECTS);
};
const closeShare = function () {
    return closeModal(MODAL_SHARE);
};
const closeCommunity = function () {
    return closeModal(MODAL_COMMUNITY);
};
const closeProjectStudent = function () {
    return closeModal(MODAL_PROJECT_STUDENT);
};
const closeCommunityProject = function () {
    return closeModal(MODAL_COMMUNITY_PROJECT);
};
const closeResetPassword = function () {
    return closeModal(MODAL_RESET_PASSWORD);
};

export {
    reducer as default,
    initialState as modalsInitialState,
    openBackdropLibrary,
    openCameraCapture,
    openCostumeLibrary,
    openExtensionLibrary,
    openLoadingProject,
    openSoundLibrary,
    openSpriteLibrary,
    openSoundRecorder,
    openTelemetryModal,
    openTipsLibrary,
    openConnectionModal,
    openLogin,
    openRegister,
    openMyProjects,
    openMyFavProjects,
    openShare,
    openCommunity,
    openCommunityProject,
    openResetPassword,
    openProjectStudent,
    closeBackdropLibrary,
    closeCameraCapture,
    closeCostumeLibrary,
    closeExtensionLibrary,
    closeLoadingProject,
    closeSpriteLibrary,
    closeSoundLibrary,
    closeSoundRecorder,
    closeTelemetryModal,
    closeTipsLibrary,
    closeConnectionModal,
    closeLogin,
    closeRegister,
    closeMyProjects,
    closeMyFavProjects,
    closeShare,
    closeCommunity,
    closeCommunityProject,
    closeResetPassword,
    closeProjectStudent
};
