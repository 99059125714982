import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import tabletFullScreen from '../lib/tablet-full-screen';

import LoginModalComponent from '../components/login-modal/login-modal.jsx';
import BrowserModalComponent from '../components/browser-modal/browser-modal.jsx';
import supportedBrowser from '../lib/supported-browser';

import {notify} from 'react-notify-toast';
import UserApi from '../api/user';

import {
    closeLogin,
    openRegister,
    openResetPassword
} from '../reducers/modals';

import {
    userLogin,
    userRegister
} from '../reducers/user';


class LoginModal extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleTryIt',
            'handleCancel',
            'handleViewRegister',
            'handleResetPassword',
            'handleLogin',
            'handleInputChange'
        ]);

        this.state = {
            username: '',
            password: '',
            error: ''
        };
    }
    handleInputChange(event) {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;

      this.setState({
        [name]: value
      });
    }

    handleTryIt () {
        // try to run in fullscreen mode on tablets.
        tabletFullScreen();
        this.props.onTryIt();
    }
    handleLogin () {
      if(this.state.username === ''||this.state.username === null){
        this.setState({error: '用户名不能为空'});
      }else if(this.state.password === ""||this.state.password === null){
        this.setState({error: '密码不能为空'});
      }else{
        const {username, password} = this.state;
        UserApi.login({username,password}).then(res => {
          notify.show('登录成功', 'success');
          this.props.onLogin({isLoggedIn:true, token: res.token, username: username, type: res.user_info.type});
        }).catch( err => {
          console.log(err)
          this.setState({error: err.message});
        });

      }
    }

    handleCancel () {
        window.location.replace('https://scratch.mit.edu');
    }
    handleViewRegister () {
      this.props.onViewRegister();
    }
    handleResetPassword () {
      this.props.onResetPassword();
    }
    render () {
        return (supportedBrowser() ?
            <LoginModalComponent
              error={this.state.error}
              onCancel={this.handleCancel}
              onTryIt={this.handleTryIt}
              onLogin={this.handleLogin}
              onViewRegister={this.handleViewRegister}
              onResetPassword={this.handleResetPassword}
              onInputChange={this.handleInputChange}
            /> :
            <BrowserModalComponent
                onBack={this.handleCancel}
            />
        );
    }
}

LoginModal.propTypes = {
    onTryIt: PropTypes.func,
    onViewRegister: PropTypes.func,
    onLogin: PropTypes.func,
    onResetPassword: PropTypes.func
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
    onTryIt: () => {
        dispatch(closeLogin());
    },
    onViewRegister: () => {
      dispatch(closeLogin());
      dispatch(openRegister());
    },
    onResetPassword: () => {
      dispatch(closeLogin());
      dispatch(openResetPassword());
    },
    onLogin: (data) => {
      dispatch(userLogin(data));
      dispatch(closeLogin());
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginModal);
