/**
 * 对sessionStorage的封装
 */
export default {
  /**
   * 获取sessionStorage
   * @param {string} name - 名称
   * @returns {promise} 获取到的值
   */
  getItem (name) {
    let ss = global.sessionStorage
    let value = ss.getItem(name)
    if (value) {
      let storage = null
      try {
        storage = JSON.parse(value)
      } catch (e) {
        storage = value
      }
      return storage
    } else {
      return null
    }
  },
  /**
   * 设置 获取sessionStorage
   * @param {string} name - 设置名称
   * @param {object} json - 设置值
   * @returns {promise} 设置成功的值
   */
  setItem (name, json) {
      let jsonString
      let ss = global.sessionStorage
      if (typeof json === 'object') {
        jsonString = JSON.stringify(json)
      } else {
        jsonString = json
      }
      ss.setItem(name, jsonString)
      return json
  }
}
